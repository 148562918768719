/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

.form-container {
  background-color: white;
  padding: 4px;
  margin-top: -2px;
  width: 100%;
  border-radius: 5px;
  padding: 29px;
  text-align-last: start;
}
ion-input {
  max-height: 30px;
  --background: white;
  --padding-start: 3px;
  // margin-top: 5px;
  color: #222;
  border: 1px solid rgb(133, 130, 130);
  border-radius: 5px;
  text-align-last: auto;
}
input {
  max-height: 30px;
  background: white;
  width: 100%;

  // margin-top: 5px;
  color: #222;
  border: 1px solid rgb(133, 130, 130);
  border-radius: 5px;
  text-align-last: auto;
}

.title {
  text-align: left;
  font: normal normal bold 28px/37px Roboto;
  letter-spacing: 0px;
  // color: #191919;
  color: #1c316e;
  font-size: 2vw;
  width: 50vw;
  opacity: 1;
}
.formsave {
  background: #f5822d 0% 0% no-repeat padding-box;
  border-radius: 40px;
  text-align: center;
  font: normal normal bold 14px/19px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  z-index: 150;
  text-transform: uppercase;
  height: 100%;
}

select {
  background-color: white;
  padding: 4px;
  border: 1px solid;
  width: 100%;
  border: 1px solid rgb(133, 130, 130);
  border-radius: 5px;
  text-align-last: start;
}
button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
::-webkit-calendar-picker-indicator {
  background-color: #0e0d0d;
}

.btn.btn-info {
  border-radius: 40px;
  text-align: center;
  font: normal normal bold 14px/19px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  z-index: 150;
  text-transform: uppercase;
  height: 100%;
}
// .ng-clear-wrapper {
//   display: none;
// }

.sessionModel {
  width: 100px;
  height: 100px;
  position: absolute;
  display: block;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000029;
  border-radius: 10px;
}
modal-wrapper .ion-overlay-wrapper {
  height: 180px;
}

.sessionModal {
  --height: 42%;
  --width: 42%;
}

.emailConfirm {
  --height: 40%;
  --width: 30%;
}

.terms_conditionModal {
  --height: 50%;
  --width: 60%;
}
.scroll-y {
  /* overflow-y: var(--overflow); */
  overscroll-behavior-y: contain;
}
.fotaCreateModel {
  --height: 50%;
}
.alertModifyModel {
  --height: 70%;
}
.alertDelete {
  --height: 35%;
  --width: 25%;
}

.fota-delete {
  --height: 35%;
}
.fota-create {
  --height: 45%;
}
